// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase:{
    apiKey: "AIzaSyAmZ1qNF4OPFVMKnccSrWdwNO9eoA4pgdE",
    authDomain: "laudelconquistador-c323b.firebaseapp.com",
    databaseURL: "https://laudelconquistador-c323b.firebaseio.com",
    projectId: "laudelconquistador-c323b",
    storageBucket: "laudelconquistador-c323b.appspot.com",
    messagingSenderId: "262335614183",
    appId: "1:262335614183:web:b09037a6dcc2211eb0a642",
    measurementId: "G-HN631BJZB6"
  }
};
