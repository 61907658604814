import { Injectable } from '@angular/core';
import { formatDate } from "@angular/common";
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2'

//Modelos

declare var $: any;

@Injectable()
export class Messages {


    constructor() {

    }


    showNotification(type, from, align, msg) {
        // const type = ['', 'info', 'success', 'warning', 'danger'];

        $.notify({
            icon: "notifications",
            message: msg

        }, {
            type: type,
            timer: 3000,
            placement: {
                from: from,
                align: align
            },        
            template: '<div data-notify="" style="z-index: 2000;" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
                '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss" >  <i class="material-icons">close</i></button>' +
                '<i class="material-icons" data-notify="icon">notifications</i> ' +
                '<span data-notify="title">{1}</span> ' +
                '<span data-notify="message">{2}</span>' +
                '<div class="progress" data-notify="progressbar">' +
                '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
                '</div>' +
                '<a href="{3}" target="{4}" data-notify="url"></a>' +
                '</div>'
        });


    }

    msgWarning(msg: string) {
        this.showNotification('warning', 'top', 'cencet', msg);
    }
    msgDanger(msg: string) {
        Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Error',
            text: msg,
            timer: 3500

          })
        // this.showNotification('danger', 'top', 'cencet', msg);
    }
    msgSuccess(msg: string) {
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: msg,
            showConfirmButton: false,
            timer: 1500
          })
        // this.showNotification('success', 'top', 'cencet', msg);
    }
    msgInfo(msg: string) {     
    this.showNotification('info', 'top', 'cencet', msg);
    }
    
    msgInfoModal(msg: string) {
       Swal.fire(msg)
   }

}