import { Injectable } from '@angular/core';

import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';

import { Observable, combineLatest, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { Router } from '@angular/router';
import { TextTilUtil } from 'app/utilidades/TextTilUtil';
import { HttpClient } from '@angular/common/http';
import { Send_email } from 'app/models/send_email';
import { AngularFireStorage } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class Send_emailService {
  send_emailCollection: AngularFirestoreCollection<Send_email>;
  send_emailList: Observable<Send_email[]>;
  send_emailDoc: AngularFirestoreDocument<Send_email>;
  send_emailSesion = [] as Send_email;


  constructor(private util: TextTilUtil,
    public dbfairebase: AngularFirestore,
    public storage: AngularFireStorage,
    public dba: AngularFireDatabase,
    private http: HttpClient) {

  }

  listarFiltro(pageSize: number, nextpage: string, adelante: boolean, tipoSearch: string, ValorSearch: any) {
    let valorVacio = true;
    if (isNaN(ValorSearch)) {
      valorVacio = this.util.isStringVacio(ValorSearch);
    } else {
      valorVacio = this.util.isNumberVacio(ValorSearch);
    }
    if (valorVacio && this.util.isStringVacio(nextpage)) {
      this.send_emailCollection = this.dbfairebase.collection('Send_email', ref => ref.orderBy('id').limit(pageSize));
    } else {
      if (this.util.isStringVacio(ValorSearch)) {
        if (adelante) {
          this.send_emailCollection = this.dbfairebase.collection('Send_email', ref => ref.orderBy('id').startAfter(nextpage).limit(pageSize));
        } else {
          this.send_emailCollection = this.dbfairebase.collection('Send_email', ref => ref.orderBy('id').startAt(nextpage).limit(pageSize));
        }
      } else {
        this.send_emailCollection = this.dbfairebase.collection('Send_email', ref => ref.orderBy(tipoSearch).startAt(ValorSearch).endAt(ValorSearch + '\uf8ff'));

      }
    }

    this.send_emailList = this.send_emailCollection.snapshotChanges().pipe(
      map(action => {
        return action.map(a => {
          const data = a.payload.doc.data() as Send_email;
          data.id = a.payload.doc.id;
          return data;
        }

        );
      }))
    return this.send_emailList;
  }

   listar() {
    this.send_emailCollection = this.dbfairebase.collection('Send_email');
    this.send_emailList = this.send_emailCollection.snapshotChanges().pipe(
      map(action => {
        return action.map(a => {
          const data = a.payload.doc.data() as Send_email;
          data.id = a.payload.doc.id;
          return data;
        }
        );
      }))
    return this.send_emailList;
  }

  async listarAsync() {
    let resp: Send_email[] = await new Promise(resolve => {
      this.listar().subscribe(email => {
        resolve(email);
      },
        error => {
          return error
        });
    });
    return resp;
  }


  async registrar(send_email: Send_email) {
    this.send_emailCollection = this.dbfairebase.collection('Send_email');
    send_email.activo=false;
      await this.send_emailCollection.add(send_email);  
  }

  async actualizarSend_email(send_email: Send_email) {
    this.send_emailDoc = this.dbfairebase.doc('piezas/' + send_email.id + "");
    await this.send_emailDoc.update(send_email);
    }
  

   actualizar(send_email: Send_email) {
    this.send_emailDoc = this.dbfairebase.doc('Send_email/' + send_email.id + "");
    this.send_emailDoc.update(send_email);
  }

 async actualizarCorreos(send_email: Send_email) {
    var lista_correos = await this.listarAsync();
    lista_correos.forEach(email => {
      email.activo=false;
      this.send_emailDoc = this.dbfairebase.doc('Send_email/' + email.id + "");
      this.send_emailDoc.update(email);

    });
    send_email.activo=true;
    this.send_emailDoc = this.dbfairebase.doc('Send_email/' + send_email.id + "");
    this.send_emailDoc.update(send_email);
  }


  eliminar(send_email: Send_email) {
    this.send_emailDoc = this.dbfairebase.doc('Send_email/' + send_email.id + "");
    this.send_emailDoc.delete();
  }



  async getSend_emailWhere(where: string, value: any) {
    let resp: Send_email[] = await new Promise(resolve => {
      this.send_emailWhere(where, value).subscribe(send_emails => {
        resolve(send_emails);
      },
        error => {
          return error
        });
    });
    return resp;
  }

  send_emailWhere(where: string, valor: any) {
    this.send_emailCollection = this.dbfairebase.collection('Send_email',
      ref => ref.where(where, '==', valor))
    this.send_emailList = this.send_emailCollection.snapshotChanges().pipe(
      map(action => {
        return action.map(a => {
          const data = a.payload.doc.data() as Send_email;
          data.id = a.payload.doc.id;
          return data;
        }

        );
      }))
    return this.send_emailList;
  }


}
