import { Component, OnInit, ElementRef } from '@angular/core';
import { ROUTES } from '../sidebar/sidebar.component';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import { UsuarioService } from 'app/services/usuario.service';
import { NotificacionService } from 'app/services/notificacion.service';
import { Usuario } from 'app/models/usuario';
import { Messages } from 'app/utilidades/Messages';
import { ActivatedRoute } from '../../../../node_modules_old/@angular/router/router';
import { Notificacion } from 'app/models/notificacion';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  private listTitles: any[];
  location: Location;
  public session;
  public cant_notificaciones = 0;
  public notificaciones = [];
  public usuario = {} as Usuario;
  mobile_menu_visible: any = 0;
  private toggleButton: any;
  private sidebarVisible: boolean;

  constructor(private _usuarioServicie: UsuarioService,
    location: Location,
    private msg: Messages,
    public _notificacionService: NotificacionService,
    public _usuarioService: UsuarioService,
    private element: ElementRef, private router: Router) {
    this.location = location;
    this.sidebarVisible = false;
  }

  listarNotificaciones() {
    // if(!this.isMobileMenu()){
    var cont=0;
    this._notificacionService.notificacionWhere('usuario_destino',this.usuario.id).subscribe( notificaciones => {
      this.notificaciones =  notificaciones; 
      this.cant_notificaciones=  notificaciones.length;
      if (notificaciones.length > 0) { 
       
        //this.msg.msgInfo('Tiene '+notificaciones.length+' Mensajes sin leer'); 
       this.msg.msgInfo(notificaciones[notificaciones.length-1].msg); 
      }
      
    });
  // }
  }

  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  };

  ngOnInit() {
    this.listTitles = ROUTES.filter(listTitle => listTitle);
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
    this.router.events.subscribe((event) => {
      this.sidebarClose();
      var $layer: any = document.getElementsByClassName('close-layer')[0];
      if ($layer) {
        $layer.remove();
        this.mobile_menu_visible = 0;
      }
    });
    this.session = this._usuarioService.checkLogin();

    if (this.session) {
      this.usuario = this._usuarioService.getUsuarioSesion();
      this.listarNotificaciones();
    }
  }

  cambiarestadoNotificacion(notificacion: Notificacion) {
    // console.log(notificacion);
    // notificacion.estado=1;
    // this._notificacionService.actualizar(notificacion);
    this._notificacionService.eliminar(notificacion);
  }

  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const body = document.getElementsByTagName('body')[0];
    setTimeout(function () {
      toggleButton.classList.add('toggled');
    }, 500);

    body.classList.add('nav-open');

    this.sidebarVisible = true;
  };
  sidebarClose() {
    const body = document.getElementsByTagName('body')[0];
    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    body.classList.remove('nav-open');
  };
  sidebarToggle() {
    // const toggleButton = this.toggleButton;
    // const body = document.getElementsByTagName('body')[0];
    var $toggle = document.getElementsByClassName('navbar-toggler')[0];

    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
    const body = document.getElementsByTagName('body')[0];

    if (this.mobile_menu_visible == 1) {
      // $('html').removeClass('nav-open');
      body.classList.remove('nav-open');
      if ($layer) {
        $layer.remove();
      }
      setTimeout(function () {
        $toggle.classList.remove('toggled');
      }, 400);

      this.mobile_menu_visible = 0;
    } else {
      setTimeout(function () {
        $toggle.classList.add('toggled');
      }, 430);

      var $layer = document.createElement('div');
      $layer.setAttribute('class', 'close-layer');


      if (body.querySelectorAll('.main-panel')) {
        document.getElementsByClassName('main-panel')[0].appendChild($layer);
      } else if (body.classList.contains('off-canvas-sidebar')) {
        document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
      }

      setTimeout(function () {
        $layer.classList.add('visible');
      }, 100);

      $layer.onclick = function () { //asign a function
        body.classList.remove('nav-open');
        this.mobile_menu_visible = 0;
        $layer.classList.remove('visible');
        setTimeout(function () {
          $layer.remove();
          $toggle.classList.remove('toggled');
        }, 400);
      }.bind(this);

      body.classList.add('nav-open');
      this.mobile_menu_visible = 1;

    }
  };
  CerrarSession() {
    this._usuarioServicie.logOut();

  }

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());

    if (titlee.charAt(0) === '/') {
      titlee = titlee.slice(1);
    }

    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return titlee.toUpperCase();
  }
}
